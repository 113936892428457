import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { restoreSettings } from './utils/settings';

const settings = restoreSettings();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    preload: ['es', 'en'],
    lng: settings ? settings.language : 'es',
    fallbackLng: 'es',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    ns: ['account', 'ads', 'dashboard', 'navbar', 'translation'],
    defaultNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;
