import axios from 'utils/axios';

export const registerUser = (user) => {
  return axios.post('/auth/register', user).then((response) => response);
};

export const updateUser = (user) => {
  return axios.put('/profile', user).then((response) => response);
};

export const getUsersById = (ids) => {
  const idsWithQuotes = ids.map((id) => `"${id}"`);
  return axios
    .get(`/users/public/list?id=[${idsWithQuotes}]`)
    .then((response) => response.data);
};

export const getUserPublicInfo = (id) => {
  return axios
    .get(`/profile/public/info/${id}`)
    .then((response) => response.data);
};

export const recoverPassword = ({ email }) => {
  return axios
    .post('/auth/reset-password/request', { email })
    .then((response) => response);
};

export const verifyRegisterToken = (token) => {
  return axios.get(`/auth/verify/${token}`).then((response) => response);
};

export const verifyResetPasswordToken = (token) => {
  return axios
    .get(`/auth/reset-password/verify/${token}`)
    .then((response) => response);
};

export const resetPassword = (token) => {
  return axios
    .post(`/auth/reset-password/reset/${token}`)
    .then((response) => response);
};

export const postResetPassword = ({ token, password, confirmPassword }) => {
  return axios
    .post(`auth/reset-password/reset/${token}`, { password, confirmPassword })
    .then((response) => response);
};

export const getProfile = () => {
  return axios.get('/profile').then((response) => response.data);
};

export const changeAvatar = (value) => {
  return axios
    .patch('/profile/update_avatar', value, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => response);
};

export const fetchCountries = () => {
  return axios
    .get('https://restcountries.eu/rest/v2/all')
    .then((response) => response.data)
    .catch((error) => error.response.statusText);
};

export const checkEmail = (email) => {
  return axios
    .get(`/users/check_email/${email}`)
    .then((response) => response.data)
    .catch(() => false);
};

export const checkMovil = (movil) => {
  return axios
    .get(`/users/check_movil/${movil}`)
    .then((response) => response.data)
    .catch(() => false);
};
