import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDataApi from 'hooks/useDataApi';
import { getDocs } from 'services/ads';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    marginBottom: theme.spacing(5),
    paddingTop: 20,
    paddingBottom: 50
  },
  logo: {
    width: 130,
    marginTop: 5,
    marginRight: theme.spacing(2)
  },
  footerImg: {
    height: 28
  },
  footerLink: {
    textDecoration: 'none',
    display: 'block',
    '& > img': {
      marginTop: theme.spacing(1.5)
    }
  }
}));
function InformationView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [{ data: docs, loading }] = useDataApi(getDocs);

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" spacing={5}>
          <Grid item xs={12} sm={3}>
            <RouterLink to="/" arial-label="Go to Home" rel="noreferrer">
              <img
                className={classes.logo}
                src="/static/images/anuncios_marca.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                marginTop: 10,
                textAlign: 'justify',
                padding: '15px, 15px, 15px, 0'
              }}
              component="p"
            >
              DETÓ Anuncios es una sucursal de negocios pertenecientes a la
              empresa DETÓ. Nos especializamos en la promoción de anuncios en
              Cuba.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('Information')}
            </Typography>
            <Link
              className={classes.footerLink}
              component={RouterLink}
              to="/about"
              variant="caption"
              color="textSecondary"
            >
              {t('About Us')}
            </Link>
            <Link
              className={classes.footerLink}
              component={RouterLink}
              to="/contact-us"
              variant="caption"
              color="textSecondary"
            >
              {t('Contact Us')}
            </Link>
            {!loading ? (
              docs &&
              docs.map((doc) => (
                <Link
                  key={doc._id}
                  className={classes.footerLink}
                  component={RouterLink}
                  to={`/docs/${doc.title.replace(/\s/g, '-')}`}
                  variant="caption"
                  color="textSecondary"
                >
                  {t(doc.title)}
                </Link>
              ))
            ) : (
              <Skeleton />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('Contact Us')}
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              +1 (786) 203 7678
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              +1 (702) 290 8661
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Link
              className={classes.footerFirstImg}
              href="https://recargas.detooo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_recargas.svg"
                alt="Detooo Anuncios"
              />
            </Link>
            <Link
              className={classes.footerLink}
              href="https://shop.detooo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_combos.svg"
                alt="Detooo Combos"
              />
            </Link>
            <Link
              className={classes.footerLink}
              href="https://www.exeditec.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_envios.svg"
                alt="Detooo Envíos"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default InformationView;
