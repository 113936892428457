import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import NavBar from './NavBar';
import Footer from './Footer';
import Information from './Information';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    // display: 'flex',
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    minHeight: '100vh'
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64 * 2
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '2.8rem'
  }
}));

function MainLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topbar}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      <main id="main" className={classes.wrapper}>
        <Container maxWidth="lg" disableGutters>
          <div className={classes.contentContainer}>
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
            <div className={classes.content}>{children}</div>
          </div>
        </Container>
      </main>
      <Information />
      <Footer className={classes.footer} />
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};

export default MainLayout;
