import { AdsApi } from 'utils/axios';

export const GET_PLANS_REQUEST = '@plans/plans-request';
export const GET_PLANS_SUCCESS = '@plans/plans-success';
export const GET_PLANS_FAILURE = '@plans/plans-failure';

export function getPlans() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PLANS_REQUEST });
      const plans = await AdsApi.get('/planes').then((res) => res.data);
      localStorage.setItem('plans', JSON.stringify(plans));
      dispatch({
        type: GET_PLANS_SUCCESS,
        payload: {
          plans
        }
      });
    } catch (err) {
      dispatch({ type: GET_PLANS_FAILURE });
      throw err;
    }
  };
}
