import axios from 'utils/axios';

export const getMunicipalitiesByProvince = (province) => {
  return axios.get(`/municipios/provincia/${province}`).then((response) => {
    return response.data;
  });
};

export const getMunicipalities = () => {
  return axios.get('/municipios/').then((response) => {
    return response.data;
  });
};

export default getMunicipalities;
