/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE
} from 'actions/categoriesActions';

const initialState = {
  categories: []
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST: {
      return produce(state, (draft) => {
        draft.categories = null;
      });
    }

    case GET_CATEGORIES_SUCCESS: {
      const categories = action.payload;
      return produce(state, (draft) => {
        draft.categories = categories;
      });
    }

    case GET_CATEGORIES_FAILURE: {
      return produce(state, () => {});
    }

    default: {
      return state;
    }
  }
};

export default categoriesReducer;
