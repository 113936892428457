import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useSettings from 'hooks/useSettings';
import SplashScreen from 'components/SplashScreenAnimated';
import { setUserData, logout } from 'actions/accountActions';
import { getCategories } from 'actions/categoriesActions';
import { getPlans } from 'actions/plansActions';

import authService from 'services/authService';
import { getAdsConfig, getNumberOfAdsCreatedToday } from 'services/ads';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const { saveSettings } = useSettings();

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        try {
          const user = await authService.loginInWithToken();
          await dispatch(setUserData(user));
          await dispatch(getPlans());

          const { limiteDiarioAnuncios: dailyAdsLimit } = await getAdsConfig();
          const myAdsCreatedToday = await getNumberOfAdsCreatedToday();

          saveSettings({
            dailyAdsLimit,
            myAdsCreatedToday
          });
        } catch {
          console.error('Problem fetching initial data');
        }
      }

      setLoading(false);
    };
    dispatch(getCategories());
    initAuth();
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
