/* eslint-dis
le react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('./views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('./views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/registersuccess',
    component: lazy(() => import('./views/auth/RegisterView/RegisterSucces'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/success',
    component: lazy(() => import('./views/auth/RegisterView/RegisterSucces'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verifytoken/:token',
    component: lazy(() => import('./views/auth/RegisterView/ActivateUser'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/resetpassword/:token',
    component: lazy(() => import('./views/auth/ForgotPassword/PasswordReset'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgotpassword',
    component: lazy(() => import('./views/auth/ForgotPassword'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Home'))
      },
      {
        exact: true,
        path: '/about',
        component: lazy(() => import('./views/pages/About'))
      },
      {
        exact: true,
        path: '/contact-us',
        component: lazy(() => import('./views/pages/Contact'))
      },
      {
        exact: true,
        path: '/docs/:title',
        component: lazy(() => import('./views/pages/Information'))
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('./views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/reports',
        component: lazy(() => import('./views/ads/Reports'))
      },
      {
        exact: true,
        path: '/orders',
        component: lazy(() => import('./views/ads/Orders'))
      },
      {
        exact: true,
        path: '/orders/:id',
        component: lazy(() => import('./views/ads/Orders'))
      },
      {
        exact: true,
        path: '/plans',
        component: lazy(() => import('./views/pages/PlansView/'))
      },
      {
        exact: true,
        path: '/myads',
        component: lazy(() => import('./views/ads/MyAds'))
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/reports/DashboardView'))
      },

      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/ads/AdCreateView'))
      },
      {
        exact: true,
        path: '/lastads',
        component: lazy(() => import('./views/ads/LastAdsView'))
      },
      {
        exact: true,
        path: '/ad/:id',
        component: lazy(() => import('./views/ads/AdDetailsView'))
      },
      {
        exact: true,
        path: '/ads/category/:slug',
        component: lazy(() => import('./views/ads/OverView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
