import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Settings from './Settings';
import Languages from './Languages';
import Account from './Account';
import CreateAdButton from './CreateAdButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  container: {
    paddingLeft: 2,
    paddingRight: 2
  },
  logo: {
    width: 130,
    marginTop: 5,
    marginRight: theme.spacing(2)
  },
  logoMovil: {
    width: 36,
    marginTop: 5
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  title: {
    marginRight: 'auto'
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  apps: {
    background: theme.palette.primary.main,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  },
  image: {
    marginRight: 5,
    width: 24,
    height: 24
  },
  closeButton: {
    color: 'white',
    position: 'absolute',
    right: 10
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  // const [toolbarVisibility, setToolbarVisibility] = useState('block');

  const handleVisibility = () => {
    document.getElementById('app-stores').remove();
    document.getElementById('main').setAttribute('class', classes.wrapper);
  };
  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <div id="app-stores">
        <Toolbar className={classes.apps}>
          <img
            className={classes.image}
            src="/static/images/google-play_white.svg"
            alt="google-play-app"
          />
          <Typography style={{ marginRight: 40 }}>Google Play</Typography>
          <img
            className={classes.image}
            src="/static/images/apple_white.svg"
            alt="apple-app"
          />
          <Typography>App Store</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleVisibility}
            aria-label="close toolbar"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </div>
      <Container maxWidth="lg" className={classes.container}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
              aria-label="Menu"
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/" arial-label="Go to Home" rel="noreferrer">
              <img
                className={classes.logo}
                src="/static/images/anuncios_marca.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
          </Hidden>
          <Box flexGrow={1} />
          <CreateAdButton />
          <Settings />
          <Languages />
          <Account account={account} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
