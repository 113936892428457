import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SunIcon from '@material-ui/icons/WbSunnyOutlined';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';

function Settings() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { settings, saveSettings } = useSettings();

  const handleChangeTheme = () => {
    const th = settings.theme === 'LIGHT' ? 'ONE_DARK' : 'LIGHT';
    saveSettings({ theme: th });
  };

  return (
    <>
      <Tooltip title={t('Theme')}>
        <IconButton
          aria-label="Change theme"
          color="primary"
          onClick={handleChangeTheme}
          ref={ref}
        >
          {settings.theme === 'LIGHT' ? (
            <img
              width={20}
              height={20}
              src="/static/images/dark_mode.svg"
              alt="dark mode"
            />
          ) : (
            <SunIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Settings;
