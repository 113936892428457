import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';

function Languages() {
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = () => {
    const l = settings.language === 'es' ? 'en' : 'es';
    i18n.changeLanguage(l);
    saveSettings({ language: l });
  };

  return (
    <>
      <Tooltip title={t('Language')}>
        <IconButton
          aria-label="Change language"
          color="primary"
          onClick={handleChangeLanguage}
          ref={ref}
        >
          <LanguageIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Languages;
