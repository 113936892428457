import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import categoriesReducer from './categoriesReducer';
import plansReducer from './plansReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  categories: categoriesReducer,
  plans: plansReducer
});

export default rootReducer;
