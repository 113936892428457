import axios from 'utils/axios';
import authService from 'services/authService';
import { updateUser } from 'services/users';
import { getProvinceById } from 'services/provinces';
import { getMunicipalitiesByProvince } from 'services/municipalities';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(
        username,
        password
      );

      const province = await getProvinceById(user.provincia);
      const municipalities = await getMunicipalitiesByProvince(user.provincia);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
          province,
          municipalities
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return async (dispatch) => {
    try {
      const province = await getProvinceById(user.provincia);
      const municipalities = await getMunicipalitiesByProvince(user.provincia);

      dispatch({
        type: SILENT_LOGIN,
        payload: {
          user,
          province,
          municipalities
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(user) {
  return async (dispatch) => {
    try {
      const response = await updateUser(user);

      if (response.status < 400) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: {
            user
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateProfileState(user) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user
      }
    });
  };
}

export const changePassword = (params) =>
  new Promise((resolve, reject) => {
    axios
      .patch('/profile/change_password', params)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
