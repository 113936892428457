import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
};

export const UsersApi = axios.create({
  baseURL: process.env.REACT_APP_USERS_API_URL,
  headers
});

export const AdsApi = axios.create({
  baseURL: process.env.REACT_APP_ADS_API_URL,
  headers
});

export const OrdersApi = axios.create({
  baseURL: process.env.REACT_APP_ORDERS_API_URL,
  headers
});

export const PaymentApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
  headers
});

export const DocsApi = axios.create({
  baseURL: process.env.REACT_APP_DOCS_API_URL,
  headers
});

UsersApi.CancelToken = axios.CancelToken;
UsersApi.isCancel = axios.isCancel;
AdsApi.CancelToken = axios.CancelToken;
AdsApi.isCancel = axios.isCancel;
OrdersApi.CancelToken = axios.CancelToken;
OrdersApi.isCancel = axios.isCancel;
PaymentApi.CancelToken = axios.CancelToken;
PaymentApi.isCancel = axios.isCancel;

export default UsersApi;
