import axios, { AdsApi, OrdersApi, PaymentApi, DocsApi } from 'utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.response.config.url.includes('change_password')
        ) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (username, password) =>
    new Promise((resolve, reject) => {
      const params = {
        username,
        password
      };
      axios
        .post('/auth/login/', params)
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  changePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      const params = {
        oldPassword,
        password: newPassword
      };

      axios
        .patch('/profile/change_password', params)
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/profile')
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      AdsApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      OrdersApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      PaymentApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      DocsApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
      delete AdsApi.defaults.headers.common.Authorization;
      delete OrdersApi.defaults.headers.common.Authorization;
      delete PaymentApi.defaults.headers.common.Authorization;
      delete PaymentApi.defaults.headers.common.Authorization;
      delete DocsApi.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    return true;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
