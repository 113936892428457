/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE
} from 'actions/plansActions';

const initialState = {
  planes: null
};

const planesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_REQUEST: {
      return produce(state, (draft) => {
        draft.plans = null;
      });
    }

    case GET_PLANS_SUCCESS: {
      const { plans } = action.payload;
      return produce(state, (draft) => {
        draft.plans = plans;
      });
    }

    case GET_PLANS_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    default: {
      return state;
    }
  }
};
export default planesReducer;
