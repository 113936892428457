import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import useSettings from 'hooks/useSettings';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';

function CreateAdButton() {
  const ref = useRef(null);
  const history = useHistory();
  const { settings } = useSettings();
  const { t } = useTranslation('ads');

  const handleCreateAd = () => {
    history.push('/create');
  };

  return (
    <>
      <Tooltip title={t('Create new ad')}>
        <IconButton
          id="createAd"
          aria-label="Create new ad"
          disabled={settings.myAdsCreatedToday >= settings.dailyAdsLimit}
          color="primary"
          onClick={handleCreateAd}
          ref={ref}
        >
          <PostAddOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default CreateAdButton;
