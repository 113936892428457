// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const CURRENCY_TYPES = [
  { value: 'CUP', name: 'Peso Cubano' },
  { value: 'USD', name: 'Dolar Estadounidense' },
  { value: 'EUR', name: 'Euro' }
];
export const INITIAL_PAGE = 1;
export const DEFAULT_LIMIT = 8;
export const DEFAULT_PROVINCE = 'Las Tunas';

export default {};
