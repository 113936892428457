import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { logout } from 'actions/accountActions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  popover: {
    width: 200,
    marginTop: 14
  },
  topButton: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.4),
      marginRight: theme.spacing(0.4)
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  icons: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.main,
    margin: theme.spacing(0.5, 1, 0.5, 0)
  }
}));

function Account({ account }) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { t } = useTranslation('translation', 'ads');
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/login');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  if (!account.user) {
    return (
      <>
        <Button
          className={classes.topButton}
          variant="outlined"
          color="secondary"
          component="a"
          href="/login"
        >
          {t('Sign In')}
        </Button>
        <Button
          className={classes.topButton}
          variant="outlined"
          color="inherit"
          component="a"
          href="/register"
        >
          {t('Sign Up')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account.user.avatar.replace(/(^\w+:|^)\/\//, '//')}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {account.user.fullname}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/dashboard">
          <DashboardIcon className={classes.icons} />
          {t('Dashboard')}
        </MenuItem>
        <MenuItem component={RouterLink} to="/myads">
          <img
            className={classes.icons}
            src="/static/images/my_ads.svg"
            alt="my ads"
          />
          {t('ads:My Ads')}
        </MenuItem>
        <MenuItem component={RouterLink} to="/orders">
          <img
            className={classes.icons}
            src="/static/images/my_orders.svg"
            alt="orders"
          />
          {t('My Orders')}
        </MenuItem>
        <MenuItem component={RouterLink} to="/reports">
          <img
            className={classes.icons}
            src="/static/images/my_reports.svg"
            alt="orders"
          />
          {t('My Reports')}
        </MenuItem>
        <MenuItem component={RouterLink} to="/account">
          <PersonIcon className={classes.icons} />
          {t('Profile')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <img
            className={classes.icons}
            src="/static/images/logout.svg"
            alt="orders"
          />
          {t('Logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

Account.propTypes = {
  account: PropTypes.object
};

export default Account;
