import { AdsApi, DocsApi } from 'utils/axios';

const setupPaginatedData = async (url) => {
  return AdsApi.get(url).then((response) => ({
    total: response.data.Total,
    items: response.data.items || []
  }));
};

export const getAdById = (id) => {
  return AdsApi.get(`/anuncios/${id}`).then((response) => response.data);
};

export const getMyAds = ({ page, limit }) => {
  return setupPaginatedData(
    `/anuncios/mis_anuncios?page=${page}&limit=${limit}`
  );
};

export const getCurrencies = () => {
  return DocsApi.get('/currencies').then((response) => response.data);
};

export const getDocs = () => {
  return DocsApi.get('/docs/filter?app=anuncios').then(
    (response) => response.data
  );
};

export const filterDocs = ({ title }) => {
  return DocsApi.get(`/docs/filter?app=anuncios&title=${title}`).then(
    (response) => response.data
  );
};

export const getNumberOfAdsCreatedToday = () => {
  return AdsApi.get('/anuncios/mis_anuncios_hoy').then(
    (response) => response.data
  );
};

export const getAdsConfig = () => {
  return AdsApi.get('/config').then((response) => response.data);
};

export const getAds = ({ page, limit }) => {
  return setupPaginatedData(`/anuncios?page=${page}&limit=${limit}`);
};

export const getLastAds = () => {
  return AdsApi.get('/anuncios/last').then((response) => response.data);
};

export const getAdsByCategory = ({ categoryId, page, limit }) => {
  return setupPaginatedData(
    `/anuncios/categoria_id/${categoryId}?page=${page}&limit=${limit}`
  );
};

export const getAdsBySlug = ({ slug, page, limit }) => {
  return setupPaginatedData(
    `/anuncios/categoria_slug/${slug}?page=${page}&limit=${limit}`
  );
};

export const filterAds = ({ queryUrl, page, limit }) => {
  return setupPaginatedData(
    `/anuncios/filter?${queryUrl}&page=${page}&limit=${limit}`
  );
};

export const getAdLikes = ({ adId }) => {
  return AdsApi.get(`/likes/anuncio/${adId}`).then((response) => response.data);
};

export const getLikesByArrayOfAds = (adsId) => {
  const idsWithQuotes = adsId.map((id) => `"${id}"`);
  return AdsApi.get(`/likes/anuncios?anuncios=[${idsWithQuotes}]`).then(
    (response) => response.data
  );
};

export const renewAd = (id) => {
  return AdsApi.get(`/anuncios/renew/${id}`).then((response) => response);
};

export const getMyReports = ({ page, limit }) => {
  return setupPaginatedData(
    `/reportes/mis_reportes?page=${page}&limit=${limit}`
  );
};

export const addLike = (anuncio) => {
  return AdsApi.post('/likes/add', { anuncio }).then(
    (response) => response.data
  );
};

export const removeLike = (adId) => {
  return AdsApi.delete(`/likes/anuncio/${adId}`).then(
    (response) => response.data
  );
};

export const PayManagerAd = (params) => {
  return AdsApi.post('checkout/anuncios/manager', params).then(
    (response) => response
  );
};

export const PayClientAd = (params) => {
  return AdsApi.post('checkout/anuncios/client/stripe', params).then(
    (response) => response
  );
};

export default getMyAds;
