import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.up('sm')]: {
      width: 400
    },

    [theme.breakpoints.down('md')]: {
      width: 300
    }
  }
}));

function LoadingScreen() {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
}

export default LoadingScreen;
