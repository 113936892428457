import getAllCategories from 'services/categories';

export const GET_CATEGORIES_REQUEST = '@categories/get-categories-request';
export const GET_CATEGORIES_SUCCESS = '@categories/get-categories-success';
export const GET_CATEGORIES_FAILURE = '@categories/get-categories-failure';

export function getCategories() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CATEGORIES_REQUEST });
      const categoriesInStorage = localStorage.getItem('categories');
      if (categoriesInStorage) {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: JSON.parse(categoriesInStorage)
        });
      }

      const categories = await getAllCategories();
      const categoriesToStorage = JSON.stringify(categories);

      if (categoriesToStorage !== categoriesInStorage) {
        localStorage.setItem('categories', categoriesToStorage);
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: categories
        });
      }
    } catch (error) {
      dispatch({ type: GET_CATEGORIES_FAILURE });
      throw error;
    }
  };
}
