import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Container, Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'inset 0px 50px 65px -40px rgba(0, 0, 0, .08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: 64
  },
  footerText: {
    color: 'white'
  }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="caption" className={classes.footerText}>
          {t('Designed by')}{' '}
          <Link
            color="inherit"
            target="_blank"
            rel="noopener"
            href="https://www.exeditec.com"
          >
            EXEDITEC
          </Link>{' '}
          © 2021 {t('All Rights Reserved')}
        </Typography>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
