import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SettingsProvider } from './context/SettingsContext';
import { configureStore } from './store';
import { restoreSettings } from './utils/settings';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);
