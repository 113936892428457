/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';

const navUser = [
  {
    items: [
      {
        title: 'Home',
        icon: 'Grid',
        href: '/'
      },
      {
        title: 'Last Ads',
        icon: 'Tag',
        href: '/lastads'
      }
    ]
  }
];

function renderNavItems(tran, { items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes(tran, { acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes(tran, { acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={tran(item.title)}
      >
        {renderNavItems(tran, {
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={tran(item.title)}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    minWidth: 220,
    top: 64,
    height: 'calc(94vh - 64px)'
  },
  logoMovil: {
    width: 60,
    marginTop: 5
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const [menu, setMenu] = useState(user ? navUser : []);

  const { categories } = useSelector((state) => state.categories);
  const { t } = useTranslation('navbar');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (categories) {
      const items = categories.map((category) => {
        const item = {
          title: category.nombre,
          icon: category.icono,
          href: `/ads/category/${category.slug}`
        };
        return item;
      });

      const newMenu = {
        subheader: 'Categories',
        href: '/ads/category',
        items
      };

      const i = menu.findIndex((n) => n.subheader === 'Categories');

      if (i < 0) {
        setMenu((prev) => [...prev, newMenu]);
      } else {
        const temp = menu;
        temp[i] = newMenu;
        setMenu(temp);
      }
    }
  }, [categories]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <img
                className={classes.logoMovil}
                width="36"
                src="/static/images/icono_detooo.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
          </Box>
        </Hidden>
        <nav style={{ padding: 8 }}>
          {menu.map((config, idx) => (
            <List
              key={idx}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {t(config.subheader)}
                </ListSubheader>
              }
            >
              {renderNavItems(t, {
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </nav>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <aside className={classes.desktopDrawer}>{content}</aside>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
