import { useEffect, useState, useReducer } from 'react';
import useIsMountedRef from './useIsMountedRef';
import { INITIAL_PAGE as page, DEFAULT_LIMIT as limit } from 'config';

const dataServiceReducer = (state, action) => {
  switch (action.type) {
    case 'SERVICE_INIT':
      return { ...state, loading: true, error: false };
    case 'SERVICE_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.items || action.payload,
        total: action.payload.total || action.payload.length
      };
    case 'SERVICE_FAILURE':
      return { ...state, loading: false, error: true };
    default:
      throw new Error();
  }
};

const useDataApi = (service, initialParams = { page, limit }) => {
  const [state, dispatch] = useReducer(dataServiceReducer, {
    loading: false,
    error: false,
    data: [],
    total: 0
  });

  const isMountedRef = useIsMountedRef();
  const { page, limit, ...rest } = initialParams;
  const isRestEmpty = Object.entries(rest).length === 0;
  const [params, setParams] = useState(initialParams);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    dispatch({ type: 'SERVICE_INIT' });
    const updatedParams = isRestEmpty ? params : { ...params, ...rest };

    service(updatedParams)
      .then((response) => {
        if (isMountedRef.current)
          dispatch({ type: 'SERVICE_SUCCESS', payload: response });
      })
      .catch(() => {
        if (isMountedRef.current) dispatch({ type: 'SERVICE_FAILURE' });
      });
  }, [service, ...Object.values(rest), params, reload, isMountedRef]);

  const changeParams = (params) => {
    setParams(params);
  };

  const reloadData = () => {
    setReload((prev) => !prev);
  };

  return [state, { params, changeParams, reloadData }];
};

export default useDataApi;
